/* global $ window */

// have a look at the breakpoints defined in _layout_variables.scss
const BP_SMALL_MAX = 704
const STATES_COUNT = 16

export default class StateAndCitiesWidget {
  constructor() {
    $(window).resize(this.resize)
    this.showCount = 4
    this.clickCount = 1
    this.offset = 4

    this.resize()
    this.registerHandler()
  }

  registerHandler() {
    const moreButton = $('.states-and-cities__show-more .btn-outline')

    moreButton.on('click', () => {
      const toShow = this.clickCount * this.showCount

      if (toShow < STATES_COUNT) {
        this.clickCount++
      }

      if (toShow === STATES_COUNT) {
        moreButton.hide()
      }

      $('.states-and-cities .simple-card').slice(0, toShow).show()
    })
  }

  resize() {
    const width = $(window).width()

    if (width < BP_SMALL_MAX) {
      this.offset = 4
    }

    if (width > BP_SMALL_MAX) {
      this.offset = 8
    }
  }
}
