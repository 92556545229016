/* global $ */
import Page from '../page'

import Events from '../../config/events'
import ProfessionCarouselWidget from '../../../../widgets/profession/carousel/script'
import MegateaserCarouselWidget from '../../../../widgets/megateaser/script'
import StateAndCitiesWidget from '../../../../widgets/state_and_cities/grid/script'
import MainSearchBar from '../../../../widgets/main_search_bar/script'
import JobPostingBookmarkWidget from '../../../../widgets/job_posting/bookmark/script'
import CorporationsCarouselWidget from '../../../../widgets/corporations/carousel/script'
import AbPubSub from '../../lib/ab_pubsub'

export default class Root extends Page {
  get containerSelector() {
    return '.js-top-job-postings-list'
  }

  constructor() {
    super()

    const container = $(this.containerSelector)
    const url = container.data('url')
    const abPubSub = AbPubSub.getInstance()

    if (url) {
      $.ajax({
        url,
      }).done((response) => {
        container.html(response)
        abPubSub.emit(Events.ON_RELOAD_LOGIN_LINKS)
      })
    }

    this.professionCarouselWidget = new ProfessionCarouselWidget()
    this.megateaserCarouselWidget = new MegateaserCarouselWidget()
    this.statesAndCitiesWidget = new StateAndCitiesWidget()
    this.mainSearchBar = new MainSearchBar()
    this.jobPostingsBookmark = new JobPostingBookmarkWidget()
    this.corporationCarouselWidget = new CorporationsCarouselWidget()
  }
}
