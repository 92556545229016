/* global $ window document */

import cookie from 'js-cookie'

export default class JobPostingBookmarkWidget {
  get bookmarkIconSelector() {
    return 'js-bookmark-job-posting'
  }

  get csrfSelector() {
    return 'meta[name="csrf-token"]'
  }

  get bookmarkSelectors() {
    return '.js-bookmark-job-posting'
  }

  get citySelectedOptionSelector() {
    return '#citySelectedOptionSelector'
  }

  constructor(containerSelector = 'job-postings-container') {
    this.containerSelector = containerSelector

    this.init()
    this.registerBookmarkOnLogin()
  }

  init() {
    this.jobPostingsContainer = document.getElementById(this.containerSelector)
    if (this.jobPostingsContainer) {
      this.registerEventListener()
    }
  }

  registerEventListener() {
    this.jobPostingsContainer.addEventListener('click', (e) => {
      if (e.target && e.target.classList.contains(this.bookmarkIconSelector) && !this.showsModal(e.target)) {
        e.preventDefault()

        const bookmark = e.target
        const {
          endpoint,
          publicId,
          vacancyCityName,
          jobPostingProfession,
          corporationId,
          jobPostingTitle,
          corporationSalesforceCategory,
          itemApplicationOptions,
        } = bookmark.dataset
        const action = this.desiredAction(bookmark)
        const csrfToken = document.querySelector(this.csrfSelector)

        const profession = jobPostingProfession
        const corpId = corporationId
        const jobTitle = jobPostingTitle
        const vacancyId = publicId
        const city = vacancyCityName

        if ($(bookmark).hasClass('job-posting-bookmark--not-saved')) {
          window.dataLayer.push({ ecommerce: null })
          window.dataLayer.push({
            event: 'add_to_wishlist',
            ecommerce: {
              currency: 'EUR',
              items: [
                {
                  index: 0,
                  item_id: vacancyId,
                  item_name: jobTitle,
                  item_brand: corpId,
                  item_category: 'Job-Posting',
                  item_category2: city,
                  item_category4: profession,
                  item_application_options: itemApplicationOptions,
                  item_status: corporationSalesforceCategory,
                  price: 1,
                  quantity: 1,
                },
              ],
            },
          })
        }

        window
          .fetch(endpoint, {
            method: action,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrfToken.content,
            },
            body: JSON.stringify({
              vacancy_public_id: publicId,
            }),
          })
          .then(this.handleResponse(bookmark))
          .catch()
      }
    })
  }

  handleResponse(element) {
    return (response) => {
      if (!response.ok) {
        return Promise.reject({
          status: response.status,
          statusText: response.statusText,
        })
      }
      this.changeIcon(element)
      return response
    }
  }

  changeIcon(element) {
    element.classList.toggle('job-posting-bookmark--not-saved')
    element.classList.toggle('job-posting-bookmark--saved')
  }

  desiredAction(bookmark) {
    return bookmark.classList.contains('job-posting-bookmark--not-saved') ? 'POST' : 'DELETE'
  }

  showsModal(element) {
    return (
      element.classList.contains('js-open_login_modal') ||
      element.classList.contains('js-open_email_update_modal') ||
      element.classList.contains('js-open_activation_modal')
    )
  }

  registerBookmarkOnLogin() {
    const popUpValue = cookie.get('triggerPopUp')

    if (popUpValue && popUpValue.includes('bookmark')) {
      cookie.remove('triggerPopUp')

      if (popUpValue.includes('false')) {
        const uuid = popUpValue.split(':')[1]
        const csrfToken = document.querySelector(this.csrfSelector)

        window.fetch('/ajax/vacancies/bookmark/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken.content,
          },
          body: JSON.stringify({
            vacancy_public_id: uuid,
          }),
        })
      }
    }
  }
}
