/* global $ */

import cookie from 'js-cookie'
import Events from '../../javascript/src/config/events'

export default class MarketingOverlayBoxWidget {
  get overlayBoxSelector() {
    return '.overlay-box'
  }
  get closeOverlaySelector() {
    return '.close-overlay'
  }

  constructor() {
    this.init()
    this.registerEventhandler()
  }

  init() {
    if (!cookie.get('overlayHidden')) {
      const overlayBox = document.querySelector(this.overlayBoxSelector)
      if (overlayBox) {
        overlayBox.classList.add('overlay-box--fade-in')
      }
    }
  }

  registerEventhandler() {
    let closeButton = document.querySelector(this.closeOverlaySelector)

    if (closeButton !== null) {
      closeButton.addEventListener('click', () => {
        cookie.set('overlayHidden', 'true', { expires: 1 })
        document.querySelector(this.overlayBoxSelector).classList.remove('overlay-box--fade-in')
        closeButton.dispatchEvent(
          new Event(Events.ON_CLOUD_MARKETING_OVERLAY_CLOSE, { bubbles: true, cancelable: true })
        )
      })
    }
  }
}
